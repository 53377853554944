import { mapActions, mapGetters, mapMutations } from 'vuex'
import dialogView from './dialog-view/index.vue'
export default {
  name: 'setting-stores',
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removedItem: null
    }
  },
  components: {
    dialogView
  },
  computed: {
    ...mapGetters({
      list: 'settingStores/list',
      limit: 'settingStores/limit',
      listLength: 'settingStores/listLength',
      isLoading: 'settingStores/isLoading',
      isListLoading: 'settingStores/isListLoading',
      openDialogView: 'settingStores/openDialogView',
      basicCountries: 'setting/basicCountries'
    }),
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'status',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  created () {
    this.fetchList()
    if (!this.basicCountries.length) this.fetchBasicCountries()
  },
  methods: {
    ...mapActions({
      fetchList: 'settingStores/GET_LIST',
      remove: 'settingStores/DELETE',
      fetchBasicCountries: 'setting/GET_BASIC_COUNTRIES'
    }),
    ...mapMutations({
      changeSkip: 'settingStores/CHANGE_SKIP',
      changeDialogView: 'settingStores/CHANGE_DIALOG_VIEW',
      changeEditId: 'settingStores/CHANGE_EDIT_ID'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialog (status = false, item = null) {
      this.changeEditId(item.id)
      this.changeDialogView(status)
    },
    changeDialogRemove (item) {
      this.showRemoveDialog = !!item
      this.removedItem = item
    },
    removeListItem () {
      this.remove(this.removedItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    }
  }
}
